.app_theme {
    --theme-palette-primary-light: #3c65a4;
    --theme-palette-primary-light-contrast: #fff;
    --theme-palette-primary-default: #2b579a;
    --theme-palette-primary-default-contrast: #fff;
    --theme-palette-primary-dark: #124078;
    --theme-palette-primary-dark-contrast: #fff;

    --theme-palette-secondary-light: #124078;
    --theme-palette-secondary-light-contrast: #fff;
    --theme-palette-secondary-default: #2b579a;
    --theme-palette-secondary-default-contrast: #fff;
    --theme-palette-secondary-dark: #3c65a4;
    --theme-palette-secondary-dark-contrast: #fff;

    --theme-palette-success-default: #10b981;
    --theme-palette-success-default-contrast: #fff;

    --theme-palette-error-light: rgb(255, 222, 222);
    --theme-palette-error-default: rgb(164, 38, 44);
    --theme-palette-error-default-contrast: #fff;;

    --theme-palette-info-default: #0ea5e9;
    --theme-palette-info-default-contrast: #fff;

    --theme-palette-warn-default: #f59e0b;
    --theme-palette-warn-default-contrast: #fff;

    --theme-palette-bg-primary: #fff;
    --theme-palette-bg-primary-accent: #f3f2f1;
    --theme-palette-bg-secondary: #edebe9;
    --theme-palette-bg-secondary-accent: #e1dfdd;
    --theme-palette-bg-tertiary: #d2d0ce;
    --theme-palette-bg-tertiary-accent: #c8c6c4;

    --theme-palette-fg-primary: #292827;
    --theme-palette-fg-primary-accent: #484644;
    --theme-palette-fg-secondary: #605e5c;
    --theme-palette-fg-secondary-accent: #797775;
    --theme-palette-fg-tertiary: #8a8886;
    --theme-palette-fg-tertiary-accent: #979593;

    --theme-effects-elevation-4: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
    --theme-effects-elevation-8: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
    --theme-effects-elevation-16: 0 6.4px 14.4px 0 rgb(0 0 0 / 13%), 0 1.2px 3.6px 0 rgb(0 0 0 / 11%);
    --theme-effects-elevation-64: 0 25.6px 57.6px 0 rgb(0 0 0 / 22%), 0 4.8px 14.4px 0 rgb(0 0 0 / 18%);
}

.app_container {
    background-color: var(--theme-palette-bg-primary-accent) !important;
}