.signin_container {
    background-color: var(--theme-palette-primary-default);
    color: var(--theme-palette-primary-default-contrast);
    width: 100%;
    height: 100vh;
    padding: 16px;
}


.signin_content {
    max-width: 1000px;
    margin: 8px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.signin_buttons_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    gap: 8px;
    width: 300px;
}

.signin_button {
    background-color: var(--theme-palette-primary-default);
    color: var(--theme-palette-primary-default-contrast);
    border: 1px solid var(--theme-palette-primary-default-contrast);
    width: 100%;
    min-height: 36px;
}