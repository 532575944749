.error_message {
    font-size: 0.875rem;
    color: var(--theme-palette-error-default);
    padding-right: 0.5rem;
}

.error_see_details_action {
    font-weight: 400;
    color: var(--theme-palette-error-default);
    text-decoration: underline;
    cursor: pointer;
}


.error_details {
    padding: 1rem;
}

.error_details table {
    width: 100%;
    border-collapse: collapse;
}

.error_details table,
.error_details table tr,
.error_details table td {
    border: 1px solid var(--theme-palette-bg-secondary);
}

.error_details table td {
    padding: 0.5rem;
}


.error_details table td.error_field_name {
    font-weight: 600;
    font-size: 0.875rem;
}

.error_details table td.error_field_value {
    font-size: 0.875rem;
}