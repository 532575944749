.root {
    --root-height: 100%;
    --title-container-height: 36px;
    --available-content-height: calc(var(--root-height) - var(--title-container-height));

    height: var(--root-height);
}

.title_container {
    height: var(--title-container-height);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.content {
    height: var(--available-content-height);
}