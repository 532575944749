.root {
    --root-height: 100%;
    --root-flex-direction: column;
    --left-section-width: 100%;
    --right-section-width: 100%;

    display: flex;
    flex-direction: var(--root-flex-direction);
    gap: 16px;
    height: var(--root-height);
}

@media screen and (min-width: 992px) {
    .root {
        --root-flex-direction: row;
        --left-section-width: calc(100% * 2 / 5);
        --right-section-width: calc(100% - var(--left-section-width));
    }
}

.content_card {
    height: var(--root-height);
}

.left_section {
   width: var(--left-section-width);
}

.right_section {
    width: var(--right-section-width);
}