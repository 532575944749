.card {
    height: 100%;
    background-color: var(--theme-palette-bg-primary);
    box-shadow: var(--theme-effects-elevation-4);
    display: flex;
    flex-direction: column;
}

.card_actions_container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid var(--theme-palette-bg-tertiary-accent);
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: flex-end;
    height: 46px;
    align-items: center;
}

.card_pagination_container {
    border-top: 1px solid var(--theme-palette-bg-tertiary-accent);
    padding: 4px 0;
    height: 46px;
}

.card_content {
    overflow: auto;
    padding: 8px;
    height: 100%;
}