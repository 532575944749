.app_skeleton {
  --header-height: 64px;
  --menu-container-width: 240px;
  --menu-width: 240px;
  --menu-display: block;
  --main-margin-left: var(--menu-width);
  --main-content-container-height: calc(100vh - var(--header-height));
}

@media screen and (max-width: 992px) {
  .app_skeleton {
    --header-height: 56px;
    --menu-container-width: 100%;
    --menu-display: none;
    --main-margin-left: 0;
    --main-content-container-height: auto;
  }
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  z-index: 41;
}

aside {
  position: fixed;
  top: var(--header-height);
  left: 0;
  bottom: 0;
  width: var(--menu-container-width);
  background-color: rgba(255,255,255,0.4);
  z-index: 51;
  display: var(--menu-display);
}

nav {
  width: var(--menu-width);
  background-color: var(--theme-palette-bg-primary);
  height: 100%;
  box-shadow: var(--theme-effects-elevation-16);
  overflow: auto;
}

main {
  position: relative;
  margin-top: var(--header-height);
  margin-left: var(--main-margin-left);
  height: var(--main-content-container-height);
  padding: 8px 16px;
  background-color: var(--theme-palette-bg-secondary);
}


.open_menu {
  display: block;
}