* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background-color: transparent;
}

.spin {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}