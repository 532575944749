.list_view {
    display: block;
}

.list_view_item {
    display: grid;
    grid-template-columns: var(--list-view-item-template-columns, auto minmax(3rem, auto));
    border: none;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
    justify-items: start;
    justify-content: start;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
}
.list_view_item_two_columns {
    background-color: transparent;
    display: flex;
    border: 0;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    justify-items: start;
    /*justify-content: start;*/
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    font-family: Arial, Verdana, sans-serif;
}

.flat_list_view_item {
    padding: 0.55rem;
}

.list_view_item_selected {
    background-color: var(--theme-palette-bg-secondary);
}

.list_view_item_deleted {
    opacity: 0.7;
    cursor: default;
}

.item_text_container {
    display: grid;
    gap: 0.25rem;
    margin-top: -0.20rem;
    width: 100%;
}
.flat_item_text_container {
    margin-top: 0;
}

.item_text_container_two_columns {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.item_text {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: start;
}

.item_primary_text {
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--theme-palette-fg-primary-accent);
}

.item_secondary_text {
    font-size: 0.75rem;
    color: var(--theme-palette-fg-secondary-accent);
}

.item_tertiary_text {
    max-width: 200px;
}

.flat_item_text {
    font-size: 0.9rem;
    color: var(--theme-palette-fg-secondary);
}