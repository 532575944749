.logo_container {
    display: var(--logo-container-display, flex);
    flex-direction: row;
    align-items: center;   
}

.app_name_text {
    display: block;
    color: var(--theme-palette-primary-dark-contrast);
    font-size: var(--logo-appname-text-font-size, 28px);
    padding-left: 8px;
    text-align: right;
}

.brand_text {
    display: block;
    color: var(--theme-palette-primary-dark-contrast);
    font-size: var(--logo-brand-text-font-size, 14px);
    letter-spacing: 0.1px;
    text-align: right;
    max-width: var(--logo-brand-text-max-width, 200px);
    margin-left: auto;
}