.appbar_container {
  width: 100%;
  padding: 4px 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--theme-palette-primary-dark);
  box-shadow: var(--theme-effects-elevation-4);
  height: 56px;

  --logo-container-display: none;
  --logo-appname-text-font-size: 19px;
  --logo-brand-text-font-size: 11px;
  --logo-brand-text-max-width: 140px;
}

.toggle_menu_button {
  display: initial;
  color: var(--theme-palette-primary-dark-contrast);
}

.logo_spacer {
  flex: 1 auto;
}

@media screen and (min-width: 576px) {
  .appbar_container {
    --logo-container-display: flex;
  }
}

@media screen and (min-width: 992px) {
  .appbar_container {
    padding: 8px 16px;
    height: 64px;

    --logo-container-display: flex;
  }

  .toggle_menu_button {
    display: none;
  }
}
