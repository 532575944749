.menu_item {
  display: block;
  padding: 8px 16px 8px 32px;
  font-size: 16px;
  text-decoration: none;
  min-height: 40px;
  position: relative;
  color: var(--theme-palatte-fg-primary);
}

.menu_item::before {
  display: none;
  width: 4px;
  background-color: var(--theme-palette-primary-default);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  content: " ";
}

.menu_item:hover {
    background-color: var(--theme-palette-bg-secondary-accent);
}

.active_menu_item {
  background-color: var(--theme-palette-bg-secondary-accent);
}

.active_menu_item::before {
    display: block;
}
