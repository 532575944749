.table_container {
    margin-top: 1rem;
}

.table_header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.table_header_label {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--theme-palette-fg-primary);
}


.table_content {
    width: 100%;
}

.table_content table {
    width: 100%;
    height: 100%;
    overflow: auto;
    border-collapse: collapse;
}

.table_content table,
.table_content table tr,
.table_content table th,
.table_content table td {
    border: 0.063rem solid var(--theme-palette-bg-tertiary-accent);
}

.table_content table th {
    padding: 0.25rem;
}

.table_content table td {
    padding: 0.25rem 0.5rem;
    vertical-align: top;
}

.table_content table th.weekday_column {
    width: 2.5rem;
}

.table_content table td.weekday_column {
    width: 2.5rem;
    padding: 0.5rem;
}

th.action_column {
    width: 2.5rem;
}

.p_none {
    padding: 0;
}