.daily_intervals_content {
    margin-top: 1rem;
}

.daily_intervals_label {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--theme-palette-fg-primary);
}

.table_header {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}


.table_container {
    width: 100%;
    /* min-height: 10rem; */
    /* background-color: var(--theme-palette-bg-secondary); */
}

.table_container table {
    width: 100%;
    height: 100%;
    overflow: auto;
    border-collapse: collapse;
}

.table_container table,
.table_container table tr,
.table_container table th,
.table_container table td {
    border: 0.063rem solid var(--theme-palette-bg-tertiary-accent);
}

.table_container table tr {
    background-color: var(--theme-palette-bg-primary);
}

.table_container table th {
    padding: 0.25rem;
}

.table_container table td {
    padding: 0.25rem 0.5rem;
    vertical-align: top;
}

.table_container table th.weekday_column {
    width: 2.5rem;
}

.table_container table td.weekday_column {
    width: 2.5rem;
    padding: 0.5rem;
}

th.action_column {
    width: 2.5rem;
}

.p_none {
    padding: 0;
}